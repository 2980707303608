export default function (actionName) {
    const googleRecaptchaClientSide = [...document.querySelectorAll('input[name="googleRecaptchaClientSide"]')];
    if (googleRecaptchaClientSide && typeof window.grecaptcha !== 'undefined' && googleRecaptchaClientSide.length) {
        window.grecaptcha.ready(function () {
            window.grecaptcha.execute(googleRecaptchaClientSide[0].value, { action: actionName }).then(function (token) {
                googleRecaptchaClientSide.forEach((input) => {
                    const parentForm = input.form;
                    const action = parentForm.dataset.recaptchaFormAction;
                    const recaptchaToken = document.createElement('input');
                    const recaptchaAction = document.createElement('input');
                    if (parentForm.querySelector('input[name="googleRecaptchaToken"]')) {
                        const existingInput = parentForm.querySelector('input[name="googleRecaptchaToken"]');
                        existingInput.parentNode.removeChild(existingInput);
                    }
                    if (parentForm.querySelector('input[name="googleRecaptchaAction"]')) {
                        const existingAction = parentForm.querySelector('input[name="googleRecaptchaAction"]');
                        existingAction.parentNode.removeChild(existingAction);
                    }
                    recaptchaToken.setAttribute('name', 'googleRecaptchaToken');
                    recaptchaToken.setAttribute('type', 'hidden');
                    recaptchaToken.setAttribute('value', token);
                    recaptchaAction.setAttribute('name', 'googleRecaptchaAction');
                    recaptchaAction.setAttribute('type', 'hidden');
                    recaptchaAction.setAttribute('value', action);
                    parentForm.prepend(recaptchaToken, recaptchaAction);
                });
            });
        });
    }
}
