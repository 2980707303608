async function getSvgSprite(svgSprite, svgSpriteElement) {
    if (!svgSprite) {
        const response = await fetch(`${window.staticImagesRoot}/sprite.svg`, {
            mode: 'no-cors',
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        });
        svgSprite = await response.text();
    }
    if (!svgSpriteElement) {
        svgSpriteElement = document.createElement('div');
        svgSpriteElement.setAttribute('id', 'svg-icons');
        svgSpriteElement.style.display = 'none';
    }
    svgSpriteElement.innerHTML = svgSprite;
    if (document.body) {
        document.body.prepend(svgSpriteElement);
        // css --vars
        const svgSpriteStyle = document.createElement('style');
        const symbols = document.querySelector('#svg-icons').querySelectorAll('symbol');
        svgSpriteStyle.innerHTML += ':root {\n';
        for (const symbol of symbols) {
            svgSpriteStyle.innerHTML += `--${symbol.id}:url( "data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='${symbol.viewBox.baseVal.x} ${symbol.viewBox.baseVal.y} ${symbol.viewBox.baseVal.width} ${symbol.viewBox.baseVal.height}'%3E ${encodeURIComponent(symbol.innerHTML)} %3C/svg%3E" );\n`;
        }
        svgSpriteStyle.innerHTML += '}';
        document.body.prepend(svgSpriteStyle);
    }
    else {
        getSvgSprite(svgSprite, svgSpriteElement);
    }
}
getSvgSprite('', undefined);
