export default function () {
    const pdfjsLib = require('pdfjs-dist');
    const pdfFiles = document.querySelectorAll('[data-pdf-path]');
    if (pdfFiles.length > 0) {
        pdfFiles.forEach(function (pdfFile) {
            let canvas = pdfFile;
            let pdfPath = pdfFile.getAttribute('data-pdf-path');
            let workerSrc = pdfFile.getAttribute('data-pdfworker-path');
            pdfjsLib.GlobalWorkerOptions.workerSrc = workerSrc;
            let loadingTask = pdfjsLib.getDocument(pdfPath);
            loadingTask.promise
                .then(function (pdfDocument) {
                for (let i = 1; i <= pdfDocument.numPages; i++) {
                    pdfDocument.getPage(i).then(function (pdfPage) {
                        let newCanvas = document.createElement('canvas');
                        newCanvas.classList.add('pdf-canvas');
                        canvas.appendChild(newCanvas);
                        let viewport = pdfPage.getViewport({
                            scale: 1.0,
                        });
                        newCanvas.width = viewport.width;
                        newCanvas.height = viewport.height;
                        let ctx = newCanvas.getContext('2d');
                        let renderTask = pdfPage.render({
                            canvasContext: ctx,
                            viewport: viewport,
                        });
                        return renderTask.promise;
                    });
                }
            })
                .catch(function (reason) {
                console.error('Error: ' + reason);
            });
        });
    }
}
