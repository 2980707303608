export default async () => {
    var _a, _b;
    let pageDiv = document.querySelector('.page');
    if (!pageDiv) {
        return;
    }
    let getWishlistUrl = pageDiv.dataset.getWishlistUrl;
    if (!getWishlistUrl) {
        return;
    }
    let wishlist;
    try {
        wishlist = await fetch(getWishlistUrl).then((r) => r.json());
    }
    catch (e) {
        console.error(e);
        return;
    }
    let pidsInWishlist = (_b = (_a = wishlist === null || wishlist === void 0 ? void 0 : wishlist.list) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.map((item) => item.id);
    if (!pidsInWishlist || !pidsInWishlist.length) {
        return;
    }
    let addToWishlistButtons = [...document.querySelectorAll('.btn-addtowishlist')];
    addToWishlistButtons.forEach((button) => {
        let vgid = button.dataset.optionVgid;
        if (pidsInWishlist.includes(vgid)) {
            button.setAttribute('data-isinwishlist', 'true');
            button.setAttribute('aria-label', button.dataset.optionLabelAdded);
        }
    });
};
