import deepFreeze from '../utils/freeze';
export default deepFreeze({
    BODY: {
        TOGGLE_OVERLAY: 'toggle:overlay',
        ON_OVERLAY: 'on:overlay',
        OFF_OVERLAY: 'off:overlay',
    },
    HEADER: {
        // IS_TRANSPARENT: 'header:isTransparent',
        IN_PDP: 'header:inPdp',
    },
    BREAKPOINTER: {
        BREAKPOINT_CHANGE: 'breakpoint:change',
    },
    ADDTOCART: {
        sizeSelected: 'size:selected',
        colorSelected: 'color:selected',
        ctaStatusSet: 'cta:statusSet',
        itemAdded: 'item:added',
        itemNotifyReady: 'item:notifyReady',
        itemNotified: 'item:notified',
        itemShowMessage: 'item.showMessage',
        trigger: 'trigger:addtocart',
        triggerAndCheckout: 'trigger:addtocartAndCheckout',
    },
    WISHLIST: {
        verify: 'wishlist:verify'
    },
    PLP: {
        refinementsUpdated: 'refinements:updated',
        sortingUpdated: 'sorting:updated',
    },
    PDP: {
        UPDATE_IMAGES_ON_COLOR_CHANGE: 'pdp:changeProductColor',
        UPDATE_SIZES: 'pdp:changeProductSize',
    },
    MODAL_EVENTS: {
        show: 'modal:show',
        close: 'modal:close',
    },
    NEWSLETTER: {
        open: 'newsletter:open',
    },
    DATALAYER: {
        NEWSLETTER: {
            open: 'datalayer:openNewsletter',
            signup: 'datalayer:signupNewsletter'
        },
        NOTIFYME: {
            open: 'datalayer:openNotifyme',
            submit: 'datalayer:submitNotifyme'
        },
        WISHLIST: {
            plp: 'datalayer:addWishlistPlp',
            pdp: 'datalayer:addWishlistPdp'
        },
        SIZE: {
            select: 'datalayer:selectSize'
        },
        MINICART: {
            open: 'datalayer:minicartOpen',
            close: 'datalayer:minicartClose'
        },
        FASTCHECKOUT: {
            begin: 'datalayer:fastCheckoutBegin'
        },
        LOGIN: {
            standard: 'login:standard',
            social: 'login:social'
        },
        CHECKOUT: {
            edit: 'datalayer:edit',
        },
        ABANDONED_CART: {
            event: "datalayer:abandonedCartEvent",
        }
    },
    SFMC: {
        CATEGORY: {
            click: "SFMC:categoryClick"
        },
        PRODUCT_CARD: {
            click: "SFMC:productCardClick"
        },
        SEARCH: {
            submit: "SFMC:searchSubmit"
        },
        CART: {
            clear: "SFMC:cartClear",
            add: "SFMC:cartAdd"
        },
        LOGIN: {
            success: "SFMC:loginSuccess",
        },
        ORDER: {
            placed: "SFMC:orderPlaced"
        }
    },
    PASSWORD: {
        created: 'password:created'
    }
});
