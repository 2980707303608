import './utils/svgSprite';
import 'what-input';
import "./libs/whatinputFix";
import OrderedDispatcher from "./libs/orderedDispatcher";
import micromodalInstance from './libs/micromodalInstance';
/* import './utils/googleTranslateForReactWorkaround'; */
import scrollUtils from './utils/scrollUtils';
import googleRecaptcha from './components/googleRecaptcha';
import pdfReader from './components/pdfReader';
import viewportUtils from './utils/viewportUtils';
import analyticsUtils from './utils/analyticsUtils';
import SFMCUtils from './utils/SFMCUtils';
import extUtils from './utils/extUtils';
import fillWishlistHeart from './utils/fillWishlistHeart';
import customEventBus from './libs/eventBus';
import CustomMessages from './constants/customMessages';
window.onload = function () {
    analyticsUtils.initListTypeCookie();
    analyticsUtils.listenForPush();
    SFMCUtils.listenForPush();
};
googleRecaptcha();
pdfReader();
window.eventQueue = new Map();
window.productSearchData = {};
/**
 * initialize global custom libraries: orderedDispatcher, scrollUtils, analyticsUtils...
 */
const orderedDispatcher = new OrderedDispatcher();
orderedDispatcher.createAsyncComponents().then(() => {
    orderedDispatcher.observeDomChanges();
});
if (document.body.getBoundingClientRect().top === 0) {
    document.documentElement.setAttribute('data-scrollposition', 'top');
}
window.addEventListener('scroll', () => {
    scrollUtils.directionHandler();
    if (document.body.getBoundingClientRect().top === 0) {
        document.documentElement.setAttribute('data-scrollposition', 'top');
    }
}, { passive: true });
extUtils.instanceTippy();
window.micromodalInstance = micromodalInstance;
window.micromodalInstance.init();
const textInputs = document.querySelectorAll('input[type="text"], input[type="email"], input[type="search"], input[type="password"], textarea');
textInputs.forEach(function (textInput) {
    const form = textInput.closest('form');
    const formGroup = textInput.closest('.form-group');
    if (formGroup && (formGroup.classList.contains('animated-label') || formGroup.classList.contains('faded-label'))) {
        textInput.addEventListener('keyup', (e) => {
            if (textInput.value.length > 0) {
                textInput.classList.add('fill');
            }
            else {
                textInput.classList.remove('fill');
            }
        });
        form.addEventListener('reset', (e) => {
            textInput.classList.remove('fill');
        });
    }
});
const viewportHeight = () => {
    if (viewportUtils.isMobile()) {
        document.documentElement.style.setProperty('--100vh', `${window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight}px`);
    }
    else {
        document.documentElement.style.setProperty('--100vh', '100vh');
    }
};
const mql = window.matchMedia('(orientation: portrait)');
mql.onchange = (e) => {
    if (e.matches) {
        // document.querySelector('html').classList.add('is-portrait');
        viewportHeight();
    }
    else {
        // document.querySelector('html').classList.add('is-landscape');
        viewportHeight();
    }
};
viewportHeight();
//verify and update inwishlist product
fillWishlistHeart();
customEventBus.on(CustomMessages.WISHLIST.verify, (data) => {
    fillWishlistHeart();
});
