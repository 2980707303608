import customEventBus from './eventBus';
/* FixVoChrome
 * workaround ad un bug di whatinput che non traccia correttamente gli eventi di keyboard sui chrome
 * implementato inizialmente per moncler/2023
 *
 * per verificare il tipo di input quindi non appoggiarsi più al data-whatinput ma alla classe keyboard-mode del body
 */
(() => {
    let inputMode = '';
    document.addEventListener('keydown', (e) => {
        if (inputMode != 'keyboard') {
            //console.warn('input changed to: keyboard');
            inputMode = 'keyboard';
            document.body.classList.add('keyboard-mode');
            customEventBus.emit('keyboard:mode', { isKeyboardMode: true, target: e.target });
        }
    });
    document.addEventListener('pointermove', () => {
        if (inputMode != 'pointer') {
            //console.warn('input changed to: pointer');
            inputMode = 'pointer';
            document.body.classList.remove('keyboard-mode');
            customEventBus.emit('keyboard:mode', { isKeyboardMode: false });
        }
    });
})();
