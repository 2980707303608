import tippy from 'tippy.js';
const extUtils = {
    instanceTippy: () => {
        tippy('[data-tooltip]', {
            content: (el) => el.dataset.tooltip,
            theme: 'light-border',
            allowHTML: true
        });
    },
};
export default extUtils;
