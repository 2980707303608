/* eslint-disable react/display-name */
__webpack_public_path__ = `${window.chunkspath || document.documentElement.dataset.publicpath}`;
import 'lazysizes';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import React, { Suspense } from 'react';
import ReactDOM from "react-dom/client";
import './scripts/static';
import './scss/main.scss';
import './utils/i18n';
console.warn('loader initializing');
if ('undefined' === typeof window._etmc) {
    window._etmc = []; // avoid errors for late instantiation of marketing cloud collect code
}
const map = new Map();
/* if (window.history.scrollRestoration) {
  window.history.scrollRestoration = 'manual';
} */
function markinitialized(el) {
    el.classList.remove('uninitialized');
    el.classList.remove('initializing');
    el.classList.add('initialized');
    window.setTimeout(() => {
        document.getElementsByTagName('body')[0].classList.add('loaded');
    }, 500);
    return true;
}
function registerComponent(name, render) {
    map.set(name, {
        component: (attrs, el) => (React.createElement(Suspense, { fallback: React.createElement("div", { className: "tmploader", dangerouslySetInnerHTML: { __html: el.innerHTML } }) }, markinitialized(el) && render(attrs))),
    });
}
registerComponents();
const els = document.querySelectorAll('[data-react]');
els.forEach((el) => {
    let reactid = el.dataset.react;
    const dataAttrs = JSON.parse(JSON.stringify(el.dataset));
    // gestisci valori booleani
    Object.keys(dataAttrs).forEach((k) => {
        if (dataAttrs[k] === 'true' || dataAttrs[k] === 'false') {
            dataAttrs[k] = dataAttrs[k] === 'true';
        }
        else if (dataAttrs[k] === 'null') {
            dataAttrs[k] = null;
        }
    });
    let val = map.get(reactid);
    if (!val) {
        console.log('Cannot find react component ' + reactid);
        return;
    }
    el.classList.add('initializing');
    dataAttrs.placeholder = el.innerHTML;
    let comp = val.component(dataAttrs, el);
    const root = ReactDOM.createRoot(el);
    root.render(comp);
});
function registerComponents() {
    const Test = React.lazy(() => import(/* webpackChunkName: "component-test" */ './components/common/Test'));
    registerComponent('test-app', (attrs) => React.createElement(Test, Object.assign({}, attrs)));
    const Checkout = React.lazy(() => import(/* webpackChunkName: "component-checkout" */ './components/checkout/CheckoutLoader'));
    registerComponent('checkout-app', (attrs) => React.createElement(Checkout, Object.assign({}, attrs)));
    const Bag = React.lazy(() => import(/* webpackChunkName: "component-bag" */ './components/bag/BagLoader'));
    registerComponent('cart-app', (attrs) => React.createElement(Bag, Object.assign({}, attrs)));
    const MiniBag = React.lazy(() => import(/* webpackChunkName: "component-minicart" */ './components/minibag/MiniBagLoader'));
    registerComponent('minicart-app', (attrs) => React.createElement(MiniBag, Object.assign({}, attrs)));
    const AccountLogin = React.lazy(() => import(/* webpackChunkName: "component-login" */ './components/account/AccountLoginLoader'));
    registerComponent('login-app', (attrs) => React.createElement(AccountLogin, Object.assign({}, attrs)));
    const AccountForgotPasswordStep1 = React.lazy(() => import(/* webpackChunkName: "component-forgotpassword-step1" */ './components/account/AccountForgotPasswordStep1Loader'));
    registerComponent('forgotpasswordstep1-app', (attrs) => React.createElement(AccountForgotPasswordStep1, Object.assign({}, attrs)));
    const AccountForgotPasswordStep2 = React.lazy(() => import(/* webpackChunkName: "component-forgotpassword-step1" */ './components/account/AccountForgotPasswordStep2Loader'));
    registerComponent('forgotpasswordstep2-app', (attrs) => React.createElement(AccountForgotPasswordStep2, Object.assign({}, attrs)));
    const AccountRegister = React.lazy(() => import(/* webpackChunkName: "component-register" */ './components/account/RegisterPageLoader'));
    registerComponent('register-app', (attrs) => React.createElement(AccountRegister, Object.assign({}, attrs)));
    const AccountAddressBook = React.lazy(() => import(/* webpackChunkName: "component-addressbook" */ './components/account/AccountAddressBookLoader'));
    registerComponent('addressbook', (attrs) => React.createElement(AccountAddressBook, Object.assign({}, attrs)));
    const AccountPreferences = React.lazy(() => import(/* webpackChunkName: "component-preferences" */ './components/account/AccountPreferencesLoader'));
    registerComponent('account-preferences', (attrs) => React.createElement(AccountPreferences, Object.assign({}, attrs)));
    const AcountProfile = React.lazy(() => import(/* webpackChunkName: "component-profile" */ './components/account/AccountProfileLoader'));
    registerComponent('profile', (attrs) => React.createElement(AcountProfile, Object.assign({}, attrs)));
    const AccountEditPassword = React.lazy(() => import(/* webpackChunkName: "component-editpassword" */ './components/account/AccountEditPasswordLoader'));
    registerComponent('edit-password', (attrs) => React.createElement(AccountEditPassword, Object.assign({}, attrs)));
    const ClientServiceContactUs = React.lazy(() => import(/* webpackChunkName: "component-clientservicecontactus" */ './components/clientService/ClientServiceContactUsLoader'));
    registerComponent('client-service-contactus', (attrs) => React.createElement(ClientServiceContactUs, Object.assign({}, attrs)));
    const Confirm = React.lazy(() => import(/* webpackChunkName: "component-register" */ './components/confirm/ConfirmLoader'));
    registerComponent('confirm-app', (attrs) => React.createElement(Confirm, Object.assign({}, attrs)));
    const NewsletterFormApp = React.lazy(() => import(/* webpackChunkName: "component-register" */ './components/newsletter/NewsletterFormApp'));
    registerComponent('newsletter-form-app', (attrs) => React.createElement(NewsletterFormApp, Object.assign({}, attrs)));
    const StoreLocatorApp = React.lazy(() => import(/* webpackChunkName: "component-storelocator-app" */ './components/storelocator/StoreLocatorApp'));
    registerComponent('storelocator-app', (attrs) => React.createElement(StoreLocatorApp, Object.assign({}, attrs)));
    const PdpExpressApp = React.lazy(() => import(/* webpackChunkName: "component-pdp-express-app" */ './components/expressPayments/PdpExpressApp'));
    registerComponent('pdp-express-app', (attrs) => React.createElement(PdpExpressApp, Object.assign({}, attrs)));
    const ExpressRecapApp = React.lazy(() => import(/* webpackChunkName: "component-pdp-express-app" */ './components/expressPayments/ExpressRecapApp'));
    registerComponent('checkoutexpress-recap-app', (attrs) => React.createElement(ExpressRecapApp, Object.assign({}, attrs)));
    const FormTester = React.lazy(() => import(/* webpackChunkName: "component-formtester" */ './components/common/FormTester'));
    registerComponent('formtester-app', (attrs) => React.createElement(FormTester, Object.assign({}, attrs)));
    const CountryPopup = React.lazy(() => import(/* webpackChunkName: "component-bag" */ './components/countrySelector/CountrySelectorPopup'));
    registerComponent('countrypopup-app', (attrs) => React.createElement(CountryPopup, Object.assign({}, attrs)));
    const Transligrade = React.lazy(() => import(/* webpackChunkName: "transligrade" */ './components/translator/Transligrade'));
    registerComponent('transligrade', (attrs) => React.createElement(Transligrade, Object.assign({}, attrs)));
}
