import customEventBus from '../libs/eventBus';
import CustomMessages from '../constants/customMessages';
let SFMCCustomMessages = CustomMessages.SFMC;
const push = (obj) => {
    if (window._etmc) {
        window._etmc.push(obj);
        console.debug('_etmc: ', window._etmc, 'lastPush: ', window._etmc);
    }
    else {
        console.warn('_etmc does not exist!');
    }
};
const SFMCUtils = {
    listenForPush: () => {
        // ********** PUSH CATEGORY ON CLICK *************************
        customEventBus.on(SFMCCustomMessages.CATEGORY.click, (data) => {
            const obj = ['trackPageView', { category: data.category }];
            push(obj);
        });
        // ********** PUSH MASTER ID ON PRODUCT CARD CLICK ***********
        customEventBus.on(SFMCCustomMessages.PRODUCT_CARD.click, (data) => {
            const obj = ['trackPageView', { item: data.masterID }];
            push(obj);
        });
        // ********** PUSH SEARCH VALUE ON SUBMIT *******************
        customEventBus.on(SFMCCustomMessages.SEARCH.submit, (data) => {
            const obj = ['trackPageView', { search: data.search }];
            push(obj);
        });
        // ********** PUSH IF USER CLEAN THE CART ********************
        customEventBus.on(SFMCCustomMessages.CART.clear, () => {
            const obj = ['trackPageView', { clear_cart: true }];
            push(obj);
        });
        // ********** PUSH ON ADD TO CART ****************************
        customEventBus.on(SFMCCustomMessages.CART.add, (data) => {
            let cart = [];
            data.cartItems.forEach((item) => {
                cart.push({
                    item: item.masterId,
                    quantity: item.quantity,
                    price: item.price.sales.formatted,
                    unique_id: item.id,
                });
            });
            let obj = ['trackCart', { cart: cart }];
            push(obj);
        });
        // ********** PUSH EMAIL ON LOGIN SUCCESS *******************
        customEventBus.on(SFMCCustomMessages.LOGIN.success, (data) => {
            let obj = ['setUserInfo', { email: data.email }];
            push(obj);
        });
    },
    pushThankyou: (items) => {
        // ********** PUSH ON CONVERSION - TYP **********************
        let order = [];
        items.forEach((item) => {
            order.push({
                item: item.masterId,
                quantity: item.quantity,
                price: item.priceTotal.price,
                unique_id: item.id,
            });
        });
        let obj = ['trackCart', { cart: order }];
        push(obj);
    }
};
export default SFMCUtils;
