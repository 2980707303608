'use strict';
const scrollUtils = {
    /**
     * scrollHandler: per verificare se ho scrollato oltre l'header (o una certa altezza),
     * e se sto scrollando UP o DOWN
     * gestendo classi sul body di conseguenza
     * @param: h
     *
     * Per attivarla:
  
  
     * */
    scrollPos: 0,
    directionHandler: function () {
        /**
         * add class scrolling-down / up to body
         * ->
         * window.addEventListener('scroll',(e)=>{
         *   scrollUtils.directionHandler();
         * }, {passive: true});
         * */
        if (document.body.getBoundingClientRect().top > this.scrollPos) {
            document.documentElement.setAttribute('data-scrolldirection', 'up');
        }
        else {
            document.documentElement.setAttribute('data-scrolldirection', 'down');
            if (document.body.getBoundingClientRect().top != 0) {
                document.documentElement.setAttribute('data-scrollposition', '');
            }
        }
        if (window.scrollY === 0) {
            document.documentElement.setAttribute('data-scrollposition', 'top');
        }
        // saves the new position for iteration.
        this.scrollPos = document.body.getBoundingClientRect().top;
    },
    intersectionHandler: function (target, callbackInside, callbackOutside, rootMargin = '0px') {
        const intersectionObserver = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    callbackInside(target);
                }
                else {
                    callbackOutside(target);
                }
            });
        }, {
            rootMargin: rootMargin,
            // threshold: [0, 1],
        });
        intersectionObserver.observe(target);
    },
    anchorHandler(offsetSelector = null) {
        /** TO DO (nice-to-have)
         * set callback  + merge it with scrollToComponent
         * */
        let anchorLinks = document.querySelectorAll('a[href^=\\#]');
        let offset = 0;
        if (offsetSelector) {
            let offsetElement = document.querySelector(offsetSelector);
            offset = offsetElement != null ? offsetElement.offsetHeight : 0;
        }
        anchorLinks.forEach((link) => {
            link.addEventListener('click', (e) => {
                if (e.currentTarget.hash !== '' && !e.currentTarget.dataset.toggle) {
                    let target = document.querySelector(e.currentTarget.hash);
                    let targetPosition = target.getBoundingClientRect().top;
                    targetPosition -= offset;
                    if (targetPosition > 0) {
                        targetPosition += window.scrollY;
                    }
                }
            }, {
                passive: true
            });
        });
    },
    toTop() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    },
    toItem(item, bottomAnchored = false, offset = 0) {
        if (item !== null) {
            let scrollTop = item.getBoundingClientRect().top + window.pageYOffset - offset;
            if (bottomAnchored) {
                scrollTop = scrollTop + item.getBoundingClientRect().height;
            }
            window.scrollTo({
                top: scrollTop,
                behavior: 'smooth'
            });
        }
    }
};
export default scrollUtils;
