import { v1 as uuid } from 'uuid';
import { ComponentDispatcher } from 'html-scoped-component-importer';
export default class OrderedDispatcher extends ComponentDispatcher {
    constructor() {
        super();
    }
    /**
    * override ComponentDispatcher/getDomComponents
    * - rimosso il reverse per poter inizializzare i componenti nell'ordine del DOM (bugfix per inizializzazione non ottimizzata)
    * - aggiunto il push dell'elemento stesso se è un componente (bugfix per caricamento singolo elemento in async)
    */
    getDomComponents(parent) {
        let domComponents = [];
        const target = parent || document.body;
        try {
            domComponents = [...target.querySelectorAll(this.componentSelector)];
            if (!!parent && parent.dataset.component) {
                domComponents.push(parent);
            }
            domComponents
                .filter((el) => !el.UUID)
                .map((el) => {
                el.UUID = uuid();
                return el;
            });
        }
        catch (e) {
            console.error(e);
        }
        return domComponents;
    }
}
