import customEventBus from '../libs/eventBus';
import CustomMessages from '../constants/customMessages';
let datalayerCustomMessages = CustomMessages.DATALAYER;
const analyticsUtils = {
    listenForPush: () => {
        customEventBus.on('datalayer:push', (data) => {
            window.dataLayer.push(data);
        });
        customEventBus.on(datalayerCustomMessages.WISHLIST.plp, (data) => {
            const dataLayerObj = {
                event: 'GAevent',
                eventID: 'rs74',
                eventCategory: 'category_listing_page',
                eventAction: 'add_to_wishlist',
                eventLabel: data.trackUrl
            };
            window.dataLayer.push(dataLayerObj);
        });
        customEventBus.on(datalayerCustomMessages.WISHLIST.pdp, (data) => {
            const dataLayerObj = {
                event: 'GAevent',
                eventID: 'rs74',
                eventCategory: 'product_page',
                eventAction: 'add_to_wishlist',
                eventLabel: data.trackUrl
            };
            window.dataLayer.push(dataLayerObj);
        });
        customEventBus.on(datalayerCustomMessages.NEWSLETTER.open, (data) => {
            const dataLayerObj = {
                event: 'GAevent',
                eventID: 'rs55',
                eventCategory: 'newsletter',
                eventAction: 'subscribe',
                eventLabel: data.trackUrl
            };
            window.dataLayer.push(dataLayerObj);
        });
        customEventBus.on(datalayerCustomMessages.NEWSLETTER.signup, () => {
            const dataLayerObj = {
                event: 'GAevent',
                eventID: 'rs56',
                eventCategory: 'newsletter_popup',
                eventAction: 'signup',
                eventLabel: 'yes'
            };
            window.dataLayer.push(dataLayerObj);
        });
        customEventBus.on(datalayerCustomMessages.NOTIFYME.open, (data) => {
            const dataLayerObj = {
                event: 'GAevent',
                eventID: 'rs74',
                eventCategory: 'product_page',
                eventAction: 'notify_me',
                eventLabel: data.trackUrl
            };
            window.dataLayer.push(dataLayerObj);
        });
        customEventBus.on(datalayerCustomMessages.NOTIFYME.submit, (data) => {
            const dataLayerObj = {
                event: 'GAevent',
                eventID: 'rs79',
                eventCategory: 'notifyme',
                eventAction: data.productID,
                eventLabel: data.category,
                size: data.size
            };
            window.dataLayer.push(dataLayerObj);
        });
        customEventBus.on(datalayerCustomMessages.SIZE.select, (data) => {
            const dataLayerObj = {
                event: 'GAevent',
                eventID: 'rs75',
                eventCategory: 'product_page',
                eventAction: 'select_size',
                eventLabel: `${data.attrValue}_${data.available ? 'available' : 'not_available'}`
            };
            window.dataLayer.push(dataLayerObj);
        });
        customEventBus.on(datalayerCustomMessages.MINICART.open, (data) => {
            const dataLayerObj = {
                event: 'GAevent',
                eventID: 'rs84',
                eventCategory: 'minicart',
                eventAction: 'minicart_open',
            };
            window.dataLayer.push(dataLayerObj);
        });
        customEventBus.on(datalayerCustomMessages.MINICART.close, (data) => {
            const dataLayerObj = {
                event: 'GAevent',
                eventID: 'rs84',
                eventCategory: 'minicart',
                eventAction: 'minicart_out',
                eventLabel: 'close'
            };
            window.dataLayer.push(dataLayerObj);
        });
        customEventBus.on(datalayerCustomMessages.FASTCHECKOUT.begin, (data) => {
            const dataLayerObj = {
                event: 'GAevent',
                eventCategory: 'fast_checkout',
                eventAction: data.location,
                eventLabel: data.payment
            };
            window.dataLayer.push(dataLayerObj);
        });
        customEventBus.on(datalayerCustomMessages.CHECKOUT.edit, (data) => {
            const dataLayerObj = {
                event: 'GAevent',
                event_category: 'editProduct',
                event_action: data.productId,
                event_label: data.productName,
            };
            window.dataLayer.push(dataLayerObj);
        });
        customEventBus.on('modal:show', (data) => {
            if (data.modalId == 'modal-search') {
                const dataLayerObj = {
                    event: 'GAevent',
                    eventID: 'rs50',
                    eventCategory: 'header',
                    eventAction: 'onsite_search',
                    eventLabel: window.location.href
                };
                window.dataLayer.push(dataLayerObj);
            }
        });
        customEventBus.on(datalayerCustomMessages.LOGIN.standard, (data) => {
            const dataLayerObj = {
                event: 'GAevent',
                event_category: 'login',
                event_action: 'Standard',
                event_label: data.loginLocation === 'checkout' ? 'Checkout_Page' : 'Generic_Login'
            };
            window.dataLayer.push(dataLayerObj);
        });
        customEventBus.on(datalayerCustomMessages.LOGIN.social, (data) => {
            const dataLayerObj = {
                event: 'GAevent',
                event_category: 'login',
                event_action: data.social,
                event_label: data.loginLocation === 'checkout' ? 'Checkout_Page' : 'Generic_Login'
            };
            window.dataLayer.push(dataLayerObj);
        });
        customEventBus.on(datalayerCustomMessages.ABANDONED_CART.event, (data) => {
            const dataLayerObj = {
                event: 'GAevent',
                event_category: 'abandoned_cart',
                event_action: data.action,
                event_label: data.abandonedCartInformation // ”True” or ”False”
            };
            window.dataLayer.push(dataLayerObj);
        });
    },
    pushCartAddToWishlist: (data) => {
        const dataLayerObj = {
            event: 'GAevent',
            eventID: 'rs74',
            eventCategory: 'cart_page',
            eventAction: 'add_to_wishlist',
            eventLabel: data.trackUrl
        };
        window.dataLayer.push(dataLayerObj);
    },
    pushCheckoutStepDatalayer: async (step, option) => {
        let forcedPipeline = '';
        switch (step) {
            case 'login':
                forcedPipeline = 'CheckoutStepLogin';
                break;
            case 'gift':
                forcedPipeline = 'CheckoutStepGift';
                break;
            case 'shipping':
                forcedPipeline = 'CheckoutStepShipping';
                break;
            case 'payment':
                forcedPipeline = 'CheckoutStepPayment';
                break;
            case 'review':
                forcedPipeline = 'CheckoutStepReview';
                break;
            default:
        }
        switch (option) {
            case 'scheme':
                option = 'credit_card';
                break;
            default:
        }
        let formData = new FormData();
        formData.append('forcedPipeline', forcedPipeline);
        if (step === 'payment') {
            formData.append('selectedPaymentMethod', option);
        }
        await fetch(window.asyncServiceUrl, {
            method: 'POST',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
            body: formData,
        }).then(r => r.json()).then((data) => {
            if (data.dataLayer) {
                window.dataLayer.push(data.dataLayer);
            }
        });
    },
    pushCheckoutSelectDatalayer: async (step, option) => {
        let stepNumber = '0';
        switch (step) {
            case 'login':
                stepNumber = '1';
                break;
            case 'gift':
                stepNumber = '2';
                break;
            case 'shipping':
                stepNumber = '3';
                break;
            case 'payment':
                stepNumber = '4';
                break;
            case 'review':
                stepNumber = '5';
                break;
            default:
        }
        if (step === 'payment') {
            switch (option) {
                case 'scheme':
                    option = 'credit_card';
                    break;
                default:
            }
        }
        let formData = new FormData();
        formData.append('forcedPipeline', 'CheckoutSelectOption');
        formData.append('step', stepNumber);
        formData.append('option', option);
        await fetch(window.asyncServiceUrl, {
            method: 'POST',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
            body: formData,
        }).then(r => r.json()).then((data) => {
            if (data.dataLayer) {
                window.dataLayer.push(data.dataLayer);
            }
        });
    },
    /* Get tiles info in listing */
    getTileListName(tile) {
        /**
         *  ○ category_listing -> per categorie
         * ○ search_result -> per search result
         * ○ homepage -> per tile/caroselli in home page
         * ○ you_might_also_like -> raccomendations in pdp
         * ○ editorial -> per tile/caroselli in pagine editoriali
         * ○ wish_list -> per tile in wishlist landing
         */
        let listName = '';
        if (document.body.querySelector('[data-action]') && document.body.querySelector('[data-action]').getAttribute('data-action') === 'Home-Show') {
            listName = 'homepage';
        }
        else if (document.body.querySelector('[data-action]') && document.body.querySelector('[data-action]').getAttribute('data-action') === 'Wishlist-Show') {
            listName = 'wish_list';
        }
        else if (document.body.querySelector('[data-listingtype]') && document.body.querySelector('[data-listingtype]').getAttribute('data-listingtype') !== '') {
            listName = document.body.querySelector('[data-listingtype]').getAttribute('data-listingtype');
        }
        else if (tile === null || tile === void 0 ? void 0 : tile.closest('[class*="einstein"]')) {
            listName = 'you_might_also_like';
        }
        else {
            listName = 'editorial';
        }
        return listName;
    },
    getTileIndex(tile) {
        let i = 0;
        let context = tile.parentNode;
        let itemSelector;
        if (tile.classList.contains('producttile')) {
            itemSelector = '.producttile';
        }
        else if (tile.classList.contains('productlistitem')) {
            itemSelector = '.productlistitem';
        }
        else {
            itemSelector = [...tile.classList][0];
        }
        i = [...context.querySelectorAll(itemSelector)].indexOf(tile) + 1;
        return i.toString();
    },
    getListPids(context) {
        let productIds = [];
        if (context.querySelectorAll('.producttile').length > 0) {
            context.querySelectorAll('.producttile').forEach((item) => {
                productIds.push(item.getAttribute('data-productid'));
            });
        }
        return productIds;
    },
    async passThroughProcessor(data, _callback = null) {
        let formData = new FormData();
        for (let k in data) {
            formData.append(k, data[k]);
        }
        await fetch(window.asyncServiceUrl, {
            method: 'POST',
            headers: {
                'X-Requested-With': 'XMLHttpRequest',
            },
            body: formData,
        })
            .then((r) => r.json())
            .then((d) => {
            if (d.dataLayer && window.dataLayer) {
                window.dataLayer.push(d.dataLayer);
            }
        })
            .catch((err) => {
            console.error(err);
        })
            .finally(() => {
            if (_callback) {
                _callback();
            }
        });
    },
    initListTypeCookie() {
        if (document.body.querySelector('[data-action]') && document.body.querySelector('[data-action]').getAttribute('data-action') === 'Product-Show') {
            return;
        }
        let listingType = this.getTileListName();
        if (listingType) {
            document.cookie = 'listType=' + listingType + '; path=/; max-age=3600000';
        }
    },
};
export default analyticsUtils;
