import MicroModal from 'micromodal';
import customEventBus from './eventBus';
const configs = {
  openTrigger: 'data-modal-trigger',
  closeTrigger: 'data-modal-close',
  // disableScroll: true,
  onShow: (modal, trigger) => {
    customEventBus.emit('modal:show', { modalId: modal.id, trigger: trigger, modal: modal });
    document.querySelector('html').classList.add('modal-open');
  },
  onClose: (modal, trigger, event) => {
    if (event) event.preventDefault();
    customEventBus.emit('modal:close', { modalId: modal.id, event: event });
    document.querySelector('html').classList.remove('modal-open');
  },
};

const micromodalInstance = {
  init() {
    MicroModal.init(configs);
  },
  forceShow(modalId) {
    MicroModal.show(modalId, configs);
  },
  forceClose(modalId) {
    MicroModal.close(modalId);
  },
};

export default micromodalInstance;
