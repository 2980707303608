'use strict';
export default {
  bp: {
    mobile: 0,
    tabletPortrait: 768,
    tabletLandscape: 1024,
    desktop: 1280,
    desktopXl: 1440,
    desktopXxl: 1920,
  },

  isMobile() {
    return !!!window.matchMedia(`(min-width: ${this.bp.tabletLandscape}px)`).matches;
  },
  isDesktop() {
    return !!window.matchMedia(`(min-width: ${this.bp.tabletLandscape}px)`).matches;
  },
};
